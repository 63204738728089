@import "../../../../assets/scss/variables";
@import "../../../../assets/scss//mixin";

.context {
  width: 300px;
  max-width: 360px;
  border-left: 1px solid map-get($colors, layout-grey-10);
  cursor: pointer;
  &:hover {
    background-color: map-get($colors, layout-grey-5);
    color: map-get($colors, layout-grey-90);
  }
  .title {
    margin: 6px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    &_label {
      @include text(10px, layout-grey-50, medium);
      margin: 0;
    }
    &_info {
      @include text(14px, layout-grey-70, bold);
      margin: 0;
    }
  }
}

.context_content {
  padding: 16px;
  min-height: 200px;
  .context_select {
    @include flex(space-between);
    min-height: 200px;
    padding: 16px 0 16px 10px;
    .input_select {
      padding: 10px 0;
      margin-left: 10px;
      width: 100%;
      height: 100%;
      min-width: 180px;
      @include text(0.875rem, layout-grey-50);
      label {
        margin-bottom: 10px;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    .btn_footer_primary {
      width: 138px;
      padding: 10px;
      background: map-get($colors, darwined-blue-50);
      border-radius: 5px;
      color: map-get($colors, foris-white);
      border: none;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background: map-get($colors, darwined-blue-70);
      }
      &.disabled {
        background: map-get($colors, layout-grey-50);
      }
    }
  }
}
