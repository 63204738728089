@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntSearchField {
  &_inputError {
    border-color: map-get($colors, message-negative-50) !important;
  }
  &_labelError {
    @include text(10, message-negative-50);
    display: block;
  }
}
