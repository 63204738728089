@import "../../../../../../../assets/scss/variables";
@import "../../../../../../../assets/scss/mixin";

.cntHeaderDemand {
  @include box(foris-white, 4px);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  .formDemand {
    @include box(layout-grey-5, 0);
    padding: 16px;
    &_text {
      @include text(14px, layout-grey-90);
    }
    .cntInputs {
      &_item {
        width: 60px;
        margin: 16px 24px 0 0;
        &:last-child {
          margin: 0 20px 0 0;
        }
      }

      &_item:disabled {
        background: map-get($colors, layout-grey-5);
      }
    }
  }
  .resumeDemand {
    min-width: 600px;
    padding: 16px;
    .totalDeman {
      border-bottom: 1px solid map-get($colors, layout-grey-10);
      padding: 0 0 0 0;
      &:last-child {
        border-bottom: none;
        padding: 10px 0 0;
      }
      &_label {
        @include text(14px, layout-grey-90, bold);
      }
      &_item {
        @include text(14px, layout-grey-90);
        .totalDeman_item__extra {
          color: map-get($colors, message-negative-50);
        }
        &_overloaded {
          color: #f4491f;
        }
        &_icon {
          color: map-get($colors, layout-grey-90);
          margin: 0 0 0 8px;
        }
      }
    }
  }
}
