//Cantidad de grillas
$grid-cols: 12;
$i: 0;

//Breakpoint

$lg: 1199.98px;
$md: 991.98px;
$sm: 767.98px;
$xs: 575.98px;
$us: 361.98px;

//Clases responsive
$col: "";
@mixin respond-to($media) {
  @if $media == lg {
    $col: "";
    @include contentGrid($col);
  } @else if $media == desktop {
    @media (max-width: $lg) {
      $col: "_pc";
      @include contentGrid($col);
    }
  } @else if $media == md {
    @media (max-width: $md) {
      $col: "_md";
      @include contentGrid($col);
    }
  } @else if $media == sm {
    @media (max-width: $sm) {
      $col: "_sm";
      @include contentGrid($col);
    }
  } @else if $media == xs {
    @media (max-width: $xs) {
      $col: "_xs";
      @include contentGrid($col);
    }
  } @else if $media == us {
    @media (max-width: $us) {
      $col: "_us";
      @include contentGrid($col);
    }
  }
}

// Tipos de justify content
$between: space-between;
$around: space-around;
$center: center;
$right: flex-end;
$justifyContent: (
  between: $between,
  around: $around,
  center: $center,
  right: $right,
);

//Menejo de offset right y left
$right: "_right";
$left: "_left";

$margin-map: (
  right: $right,
  left: $left,
);

@mixin decimalMargin() {
  @for $i from 0 through $grid-cols {
    @each $key, $value in $margin-map {
      &#{$value}_#{$i} {
        @if $i == $grid-cols {
          margin-#{$key}: 100%;
        } @else if $i != $grid-cols and $i != 0 {
          margin-#{$key}: (100 / ($grid-cols / $i)) * 1%;
          @for $d from 1 through 9 {
            &_#{$d} {
              margin-#{$key}: (100 / ($grid-cols / ($i + $d/10))) * 1%;
            }
          }
        } @else if $i == 0 {
          margin-#{$key}: 0;
          @for $d from 1 through 9 {
            &_#{$d} {
              margin-#{$key}: (100 / ($grid-cols / ($i + $d/10))) * 1%;
            }
          }
        }
      }
    }
  }
}

@mixin contentGrid($col) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.row#{$col} {
    @each $key, $value in $justifyContent {
      &--#{$key} {
        justify-content: $value;
      }
    }
  }
  &.row_align {
    @each $key, $value in $justifyContent {
      &--#{$key} {
        align-items: $value;
      }
    }
  }
  &.row--wrap {
    flex-wrap: wrap;
  }
  &.row#{$col}--block {
    display: block;
  }
  &.row#{$col}--reverse {
    flex-direction: row-reverse;
  }
  &.row--padding {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    > [class*="col_"] {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
  }

  @for $i from 0 through $grid-cols {
    .col#{$col}_#{$i},
    .col_not#{$col}_#{$i} {
      @if $i == $grid-cols {
        flex-basis: 100%;
      } @else if $i != $grid-cols and $i != 0 {
        flex-basis: (100 / ($grid-cols / $i)) * 1%;
        max-width: (100 / ($grid-cols / $i)) * 1%;
        @for $d from 1 through 9 {
          &_#{$d} {
            flex-basis: (100 / ($grid-cols / ($i + $d/10))) * 1%;
            max-width: (100 / ($grid-cols / ($i + $d/10))) * 1%;
          }
        }
      } @else if $i == 0 {
        flex-basis: 0;
        @for $d from 1 through 9 {
          &_#{$d} {
            flex-basis: (100 / ($grid-cols / ($i + $d/10))) * 1%;
            max-width: (100 / ($grid-cols / ($i + $d/10))) * 1%;
          }
        }
      }
    }
  }

  .col_offset#{$col} {
    @include decimalMargin;
  }
  .col_hidden#{$col} {
    display: none;
  }
  .col_show#{$col} {
    display: flex;
  }
} //container-row

.container {
  margin: 0 auto;
  &.container--full {
    height: calc(100% - 59px);
  }
  &-row {
    @include respond-to(lg);
    @include respond-to(desktop);
    @include respond-to(md);
    @include respond-to(sm);
    @include respond-to(xs);

    > [class*="col_"] {
      width: auto;
      height: auto;
    }
  }
} //container
