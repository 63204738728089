// Breakpoint
$lg: 1199.98px;
$md: 991.98px;
$sm: 767.98px;
$xs: 575.98px;
$us: 361.98px;

$font-stack: "Roboto", sans-serif, Arial;

$fonts: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
);

$colors: (
  foris-blue-90: #005066,
  foris-blue-70: #0b718e,
  foris-blue-50: #00abc8,
  foris-blue-30: #5ecbdc,
  foris-blue-10: #bce9f0,
  darwined-blue-90: #1a365b,
  darwined-blue-70: #294870,
  darwined-blue-50: #376197,
  darwined-blue-30: #507aaf,
  darwined-blue-10: #6993c9,
  foris-green-90: #306e55,
  foris-green-70: #52987c,
  foris-green-50: #6bbe9c,
  foris-green-30: #add8c4,
  foris-green-10: #e0efe7,
  layout-grey-90: #343a40,
  layout-grey-70: #575d62,
  layout-grey-50: #6a7075,
  layout-grey-30: #c5c8cd,
  layout-grey-10: #e5e8ec,
  layout-grey-5: #f4f5f6,
  layout-black: #000000,
  layout-white: #fafafa,
  message-negative-30: #ff5f58,
  message-negative-50: #e33932,
  message-alert-50: #f5aa42,
  message-alert-30: #ffc16b,
  message-positive-50: #3fbc89,
  message-positive-30: #54cb97,
  foris-white: #ffffff,
  foris-login-text: #7f8fa4,
  test-zeplin: #f5f5f5,
  blueEvent: #265985,
  darwined-login: #2a4870,
);

$zIndex: (
  basic: 1,
  element: 2,
  intermediate: 3,
  select: 4,
  modal: 10,
  menu: 11,
);

$black: hsl(0, 0%, 4%) !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;

$grey-darker: hsl(0, 0%, 21%) !default;
$grey-dark: hsl(0, 0%, 29%) !default;
$grey: hsl(0, 0%, 48%) !default;
$grey-light: hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter: hsl(0, 0%, 96%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: hsl(0, 0%, 100%) !default;

$orange: hsl(14, 100%, 53%) !default;
$yellow: hsl(48, 100%, 67%) !default;
$green: hsl(141, 71%, 48%) !default;
$turquoise: hsl(171, 100%, 41%) !default;
$cyan: hsl(204, 86%, 53%) !default;
$blue: hsl(217, 71%, 53%) !default;
$purple: hsl(271, 100%, 71%) !default;
$red: hsl(348, 100%, 61%) !default;

// Typography

$family-sans-serif: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.75rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
// 1344px container + 4rem;
$fullhd: 1344px + (2 * $gap) !default;

// Miscellaneous

$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 3px !default;
$radius-large: 5px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags

$variable-columns: true !default;

// The default Bulma derived variables are declared below

$primary: $turquoise !default;

$info: $cyan !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: $turquoise-invert !default;
$info-invert: $cyan-invert !default;
$success-invert: $green-invert !default;
$warning-invert: $yellow-invert !default;
$danger-invert: $red-invert !default;
$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors

$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors

$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors

$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors

$link: $blue !default;
$link-invert: $blue-invert !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-border: $grey-light !default;

$link-focus: $grey-darker !default;
$link-focus-border: $blue !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-dark !default;

// Typography

$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-4 !default;
