@import "../../../assets/scss/variables";
@import "../../../assets/scss//mixin";

.header {
  height: 50px;
  position: relative;
  background: map-get($colors, foris-white);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  .cntImage {
    @include flex(center, center);
    min-width: 150px;
    max-width: 150px;
    padding: 0 8px;
    border-left: 1px solid map-get($colors, layout-grey-10);
    cursor: pointer;
    // cuando se importe avocado eliminar este tag en scss
    figure {
      margin: 0;
      .img {
        width: 100%;
        margin: 0;
      }
    }
  }
  .cntInfo {
    @include flex(flex-start, center);
    flex-grow: 1;
    margin: 8px 0;
    padding: 0 10px;
    border-left: 1px solid map-get($colors, layout-grey-30);
    &_nameModule {
      @include text(26px, layout-grey-30, medium);
    }
  }
  .products {
    @include flex(center, center);
    min-width: 50px;
    border-left: 1px solid map-get($colors, layout-grey-10);
    border-right: 1px solid map-get($colors, layout-grey-10);
    cursor: pointer;
  }
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}
